import React from 'react'

import demo1 from '../../images/demo/1.jpg'
import demo2 from '../../images/demo/2.jpg'
import demo3 from '../../images/demo/3.jpg'
import demo4 from '../../images/demo/4.jpg'

const Demo = () =>{
    return(
        <section className="wpo-demo-section section-padding" id="demo">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col col-lg-4">
                <div className="wpo-section-title">
                    <h2>Unique Home Pages</h2>
                    <p>The Ultimate Niche WordPress Theme for the Lawyer & Attorney</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-xs-12">
                <div className="wpo-demo-grids clearfix">
                    <div className="grid" >
                        <div className="inner">
                            <a href="https://wpocean.com/wp/canun/" target="_blank"><img src={demo1} alt=""/></a>
                        </div>
                        <h3>Home page default</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/wp/canun/home-style-2/" target="_blank"><img src={demo2} alt=""/></a>
                        </div>
                        <h3>Home style 2</h3>
                    </div>
                    <div className="grid">
                        <div className="inner">
                            <a href="https://wpocean.com/wp/canun/home-style-3/" target="_blank"><img src={demo3} alt=""/></a>
                        </div>
                        <h3>Home style 3</h3>
                    </div>
                    <div className="grid">
                        <div className="coming-inner">
                            <img src={demo4} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    )
}

export default Demo;